import * as React from "react"
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"

const ChangePlan = () => {
  const {t} = useTranslation();

  const title = t("course.title");
  const topFaqList = [
    {faqLink: "/akamaru/faq/0004", faqTitle: t("faq.currentPurchasingStatus.question")},
    {faqLink: "/akamaru/faq/0009", faqTitle: t("faq.freePlay.question")},
    {faqLink: "/akamaru/faq/0011", faqTitle: t("faq.bonusPlay.question")},
    {faqLink: "/akamaru/faq/0007", faqTitle: t("faq.howToUnsubscribe.question")},
  ];

  return (
    <AppLayout>
      <Seo title={title} isIndex={false} pagePath={"/product/akamaru"}/>
      <article className="container page-article-main">
        <h1>{title}</h1>

        <section>
          <h2>{t("course.compareCourse.title")}</h2>
          <p>{t("course.compareCourse.lead.item1")}</p>
          <CompareCourse/>

          <h3>{t("course.toAdvancedCourse.contents.section2.title")}</h3>
          <p>{t("course.toAdvancedCourse.contents.section2.lead")}</p>
        </section>

        <section>
          <h2>{t("course.changePlan.title")}</h2>
          <p>{t("course.lead")}</p>

          <ul>
            <li>
              <a href="#for-subscription">{t("course.advanced")}</a>
            </li>
            <li>
              <a href="#for-free">{t("course.free")}</a>
            </li>
          </ul>

          <>
            <h3 id={"for-subscription"}>{t("course.toAdvancedCourse.title")}</h3>

            <ol>
              <li>{t("course.toAdvancedCourse.contents.section1.item1")}</li>
              <li>{t("course.toAdvancedCourse.contents.section1.item2")}</li>
              <li>{t("course.toAdvancedCourse.contents.section1.item3")}</li>
            </ol>
          </>

          <>
            <h3 id={"for-free"}>{t("course.toFreeCourse.title")}</h3>

            <p>{t("course.toFreeCourse.contents.section1.lead")}</p>
            <p>{t("course.toFreeCourse.contents.section2.item1")}</p>
            <p>{t("course.toFreeCourse.contents.section2.item2")}</p>
            <p dangerouslySetInnerHTML={{__html: t("course.toFreeCourse.contents.section2.item3")}}/>
            <p>{t("course.toFreeCourse.contents.section2.item4")}</p>
          </>
        </section>

        <section>
          <h2>{t("course.otherQuestions.title")}</h2>
          <p>{t("course.otherQuestions.lead")}</p>
          <ul>
            {
              topFaqList.map(faq => {
                return (
                  <li>
                    <a href={faq.faqLink}>{faq.faqTitle}
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </section>

      </article>
    </AppLayout>
  );
}

export default ChangePlan

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CompareCourse = () => {
  const {t} = useTranslation();

  const freePlan = t("course.compareCourse.name.free");
  const subscribePlan = t("course.compareCourse.name.advanced");
  const drillPlan = t("course.compareCourse.name.drills");

  return (
    <>
      <table className="compare-table">
        <tr className="thead">
          <th></th>
          <th>{freePlan}</th>
          <th>{subscribePlan}</th>
          <th>{drillPlan}</th>
        </tr>


        <tr>
          <td>
            {t("course.compareCourse.gameTitle.title")}
          </td>
          <td data-label={freePlan}>
            {t("course.compareCourse.gameTitle.free")}</td>
          <td data-label={subscribePlan} className="strong">
            {t("course.compareCourse.gameTitle.advanced")}
          </td>
          <td data-label={drillPlan} className="strong">
            {t("course.compareCourse.gameTitle.drills")}
          </td>
        </tr>

        <tr>
          <td>
            {t("course.compareCourse.pairGamePlayCount.title")}
          </td>
          <td data-label={freePlan}>
            {t("course.compareCourse.pairGamePlayCount.free")}</td>
          <td data-label={subscribePlan} className="strong">
            {t("course.compareCourse.pairGamePlayCount.advanced")}
          </td>
          <td data-label={drillPlan} className="strong">
            {t("course.compareCourse.pairGamePlayCount.drills")}
          </td>
        </tr>

        <tr>
          <td>
            {t("course.compareCourse.playDrill.title")}
          </td>
          <td data-label={freePlan}>
            {t("course.compareCourse.playDrill.free")}</td>
          <td data-label={subscribePlan} className="strong">
            {t("course.compareCourse.playDrill.advanced")}
          </td>
          <td data-label={drillPlan} className="strong">
            {t("course.compareCourse.playDrill.drills")}
          </td>
        </tr>

        <tr>
          <td>
            {t("course.compareCourse.price.title")}
          </td>
          <td data-label={freePlan}>
            {t("course.compareCourse.price.free")}
          </td>
          <td data-label={subscribePlan} className="strong">

            {t("course.compareCourse.price.advanced")}

            <span className='td-note'>
              {t("course.compareCourse.price.note")}
            </span>
          </td>
          <td data-label={drillPlan} className="strong">
            {t("course.compareCourse.price.drills")}
            <span className='td-note'>
              {t("course.compareCourse.price.drillNote")}
            </span>
          </td>
        </tr>
      </table>

      <span className="table-note">
        {t("course.compareCourse.note")}
      </span>
    </>
  )
}
